var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"items":_vm.items,"headers":_vm.headers,"hide-default-footer":"","items-per-page":-1,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('verify-tin-dialog',{attrs:{"signer-data":item,"trainer-tin":_vm.trainerTin},on:{"tinVerified":function($event){return _vm.startSignatureProcess($event)}}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getStudentName(item.studentInfo))+" ")]}},{key:"item.signatureAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.pendingDocuments && item.pendingDocuments.length) || "-")+" ")]}},{key:"item.tin",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.hideTin(item.tin))+" ")]}},{key:"no-data",fn:function(){return [_c('div',{},[_c('v-icon',{staticClass:"mt-5",attrs:{"color":"primary","size":"80"}},[_vm._v("mdi-checkbox-marked-circle-outline")]),_c('p',{staticClass:"text-h5"},[_vm._v("No hay firmas pendientes")])],1)]},proxy:true}])}),(_vm.signatureDialogData)?_c('signature-dialog',{key:_vm.signatureDialogKey,attrs:{"signature-dialog-visible":_vm.signatureDialogVisible,"signer-data":_vm.signatureDialogData,"semicyuc":_vm.semicyuc},on:{"closeDialog":_vm.closeSignatureDialog}}):_vm._e()],1),_c('v-col',{staticClass:"mt-10 text-center",attrs:{"cols":"12"}},[_c('verify-password-btn-dialog',{on:{"success":_vm.exitSignaturesView}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }