<template>
  <v-row>
    <v-col cols="12">
      <v-data-table
        :items="items"
        :headers="headers"
        hide-default-footer
        :items-per-page="-1"
        :loading="loading"
      >
        <template #item.actions="{ item }">
          <verify-tin-dialog
            :signer-data="item"
            :trainer-tin="trainerTin"
            @tinVerified="startSignatureProcess($event)"
          />
        </template>

        <template #item.name="{ item }">
          {{ getStudentName(item.studentInfo) }}
        </template>

        <template #item.signatureAmount="{ item }">
          {{ (item.pendingDocuments && item.pendingDocuments.length) || "-" }}
        </template>

        <template #item.tin="{ item }">
          {{ hideTin(item.tin) }}
        </template>

        <template #no-data>
          <div class="">
            <v-icon class="mt-5" color="primary" size="80"
              >mdi-checkbox-marked-circle-outline</v-icon
            >
            <p class="text-h5">No hay firmas pendientes</p>
          </div>
        </template>
      </v-data-table>

      <signature-dialog
        v-if="signatureDialogData"
        :signature-dialog-visible="signatureDialogVisible"
        :signer-data="signatureDialogData"
        :semicyuc="semicyuc"
        :key="signatureDialogKey"
        @closeDialog="closeSignatureDialog"
      />
    </v-col>

    <v-col cols="12" class="mt-10 text-center">
      <verify-password-btn-dialog @success="exitSignaturesView"></verify-password-btn-dialog>
    </v-col>
  </v-row>
</template>

<script>
import signatureMixin from "@/modules/cardioguard-trainer/mixins/signatureProcessMixin";

export default {
  components: {
    VerifyPasswordBtnDialog: () => import("@/components/verifyPasswordBtnDialog"),
  },
  mixins: [signatureMixin],
  data: () => ({
    signatureMoments: { signatureMoments: ["before"] },
  }),
  methods: {
    exitSignaturesView() {
      this.$router.push({ name: "trainerSession", params: { id: this.$route.params.id } });
    },
  },
};
</script>

<style scoped></style>
